
    import { defineComponent } from 'vue';
    import { IonList, IonCard, IonImg } from '@ionic/vue';
    import { PublicationApiClient, PublicationMainCategoryModel} from '../../generated';
    import { store } from '../../store/store';

    export default defineComponent({
        name: 'Publications',
        components: {
            IonList,
            IonCard,
            IonImg,
        },
        data() {
            return {
                publications: Array<PublicationMainCategoryModel>(),
            }
        },
        ionViewDidEnter() {
            this.loadData();
        },
        computed: {
            getPublications(): Array<PublicationMainCategoryModel> {
                return this.publications.filter(x => !x.hideInMenu);
            }
        },
        methods: {
            loadData: function () {
                const publicationsClient = new PublicationApiClient();
                publicationsClient.getPublicationMainCategories(store.state.currentLanguage).then(result => {
                    if (result != null) {
                        console.log(result)
                        this.publications = result;
                    }
                });
                
            }
        }
    })
